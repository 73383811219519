import React from "react";
import { graphql, Link } from "gatsby";

import SEO from "../components/seo";
import CarouselLayout from "../components/carousel-layout";
import { toPlainText } from "../lib/helpers";

import * as styles from "./contribution.module.css";

export const query = graphql`
  query ContributionTemplateQuery($id: String!) {
    post: sanityContribution(id: { eq: $id }) {
      id
      mainImage {
        ...SanityImage
        alt
      }
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      cycle {
        slug {
          current
        }
        theme
      }
      authors {
        author {
          id
          name
          twitterName
          _rawBio(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const ContributionTemplate = ({ data }) => {
  const post = data?.post;
  if (!post) return null;
  return (
    <>
      <SEO
        title={post.title || "Untitled work"}
        description={toPlainText(post._rawExcerpt)}
        image={post.mainImage}
      />
      <CarouselLayout
        title={
          <div className={styles.headerInner}>
            <div>
              <Link to={`/${post.cycle.slug.current}`}>{post.cycle.theme}</Link>
              &nbsp;– <h1 className="inline">{post.title}</h1>
            </div>
            <a href="#contributors">
              {post.authors.map(({ author }) => author.name).join(", ")}
            </a>
          </div>
        }
        blocks={post._rawBody}
        contributors={post.authors}
      />
    </>
  );
};

export default ContributionTemplate;
